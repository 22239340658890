<template>
  <SwipeDialog
    :title="title"
    :context-menu-actions="contextMenuActions"
    :show-context-menu="showContextMenu"
    style="height: 100%; overflow: scroll"
    @closeFn="$router.go(-1)"
    @contextMenuFn="contextMenuFn"
  >
    <van-tabs
      v-model="tabIndex"
      color="#1989fa"
      style="position: sticky; top: 0px; z-index: 9"
    >
      <van-tab
        v-for="item in [name + '信息', '拜访反馈']"
        :key="item"
        :title="item"
      />
    </van-tabs>
    <div style="padding: 10px">
      <!-- 拜访信息 -->
      <div v-show="tabIndex === 0">
        <div v-for="(item, index) in fields" :key="index">
          <SchemaField
            v-if="item?.field"
            v-model="formState[item.field]"
            :schema="item"
            :editable="editable"
          />
          <SchemaField v-else-if="item?.type === 'space'" :schema="item" />
        </div>
        <div
          v-if="formState.physician_id"
          style="padding: 5px; background-color: #fff; text-align: left"
        ></div>
        <!-- 费用信息 start -->
        <div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="
                font-size: 16px;
                text-align: left;
                font-weight: bold;
                padding: 20px 10px 10px 10px;
              "
            >
              费用信息
            </div>
            <van-button
              v-if="showAddExpense && editable"
              type="danger"
              round
              size="small"
              plain
              style="margin: 15px 0 10px 0"
              @click="deleteDiner"
            >
              删除餐费
            </van-button>
          </div>

          <div
            v-if="!showAddExpense"
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 10px;
            "
          >
            <div
              style="
                font-size: 14px;
                text-align: left;
                color: #7f7f81;
                padding-left: 10px;
              "
            >
              如本次拜访涉及餐费报销，<br />
              请在此添加餐费详情。
            </div>
            <van-button
              v-if="editable"
              type="info"
              round
              size="small"
              plain
              @click="showAddExpense = true"
            >
              添加餐费
            </van-button>
          </div>
          <AddExpense
            v-show="showAddExpense"
            ref="addExpense"
            :show-add-expense="showAddExpense"
            :show-cost-proportion="showCostProportion"
            :cost-proportion="costProportion"
            :def-doctors="
              formState.physician_mr_call__physician_mr_call_diner_physicians
            "
            :def-colleagues="
              formState.physician_mr_call__physician_mr_call_diner_users
            "
            :budget-res="callBudget"
            :call-products="defCall_products"
            :def-diner-amount="defDinerAmount"
            :def-editable="editable"
          />
        </div>
        <!-- 费用信息 end -->
        <div
          v-if="formState.call_product_id?.includes('Brintellix_000001')"
          style="padding: 12px"
        >
          <div
            style="
              font-size: 16px;
              text-align: left;
              font-weight: bold;
              padding: 20px 0 10px 0;
            "
          >
            建议内容
          </div>
          <BrintellixSuggestion :physician_id="formState.physician_id" />
        </div>
      </div>
      <!-- 拜访反馈 -->
      <div v-show="tabIndex === 1">
        <div
          v-if="formState.call_product_id?.includes('Brintellix_000001')"
          style="padding: 12px"
        >
          <div style="font-size: 16px; text-align: left; font-weight: bold">
            建议内容
          </div>

          <BrintellixSuggestion
            :physician_id="formState.physician_id"
            @nbaLoaded="nbaLoaded"
          />
        </div>
        <div style="background: #fff; padding: 10px 0">
          <div style="padding: 10px">
            <div
              style="
                font-size: 16px;
                text-align: left;
                font-weight: bold;
                padding-bottom: 20px;
              "
            >
              反馈内容
            </div>
            <div class="question">此次互动目标是否达成？</div>
            <div class="space">
              <van-button
                v-for="(item, index) in ['尚未', '达成']"
                :key="index"
                :disabled="!editable"
                type="info"
                round
                size="small"
                :plain="formState.feedback_call_object_meet !== item"
                @click="formState.feedback_call_object_meet = item"
              >
                {{ ['×', '√'][index] }} {{ item }}
              </van-button>
            </div>
          </div>
          <div style="padding: 10px">
            <div class="question">专家是否可能发生分型或认可阶段转变？</div>
            <div class="space">
              <van-button
                v-for="(item, index) in ['没有', '有可能']"
                :key="index"
                :disabled="!editable"
                type="info"
                round
                size="small"
                :plain="formState.feedback_segment_ladder_changed !== item"
                @click="formState.feedback_segment_ladder_changed = item"
                >{{ ['×', '√'][index] }} {{ item }}</van-button
              >
            </div>
          </div>
          <div v-if="false">
            <div class="question" style="padding: 10px 10px 0">
              请填写您收集到的医生观念信息：
            </div>
            <div style="margin: 0 -6px">
              <SchemaField
                v-model="formState.call_description"
                :schema="{type: 'textarea'}"
              />
              <SpeechToText
                @translateResult="formState.call_description += $event"
              />
            </div>
          </div>
        </div>
      </div>
      <div style="height: 40px"></div>
      <BottomAction
        :confirm-loading="saveLoading"
        :editable="editable"
        @save="save"
      />
    </div>
  </SwipeDialog>
</template>

<script>
import Vue from 'vue';
import BrintellixSuggestion from '@/views/hcp/components/NBA/BrintellixSuggestion.vue';
import {Dialog, Tab, Tabs, Picker, Popup, Icon, Button, Toast} from 'vant';
import SwipeDialog from '@/views/layout/swipeDialog';
import SchemaField from '@/components/form/SchemaField.vue';
import BottomAction from './components/BottomAction.vue';
import AddExpense from './components/addExpense.vue';
import {
  getUserPhysicianProduct,
  fetchOnePhysicianMrCall,
  getGetMRCallBudget,
  createMrCall,
  deleteMrCall,
  createCRMCall,
  updateCRMCall,
  deleteCRMCall,
} from '@/api/Physicians';
import {validateFields} from './util';
import {physicianListOptionsMixins} from '@/mixins/physicianListOptions';
import kvpKeysMixin from '@/mixins/kvpKey';
import {getLocalStorage} from '@/utils';
import {fetchVUsers} from '../../api/kol';
import SpeechToText from '@/components/SpeechToText.vue';

Vue.use(Popup);
Vue.use(Picker);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(Button);

export default {
  components: {
    SwipeDialog,
    SchemaField,
    BrintellixSuggestion,
    BottomAction,
    SpeechToText,
    AddExpense,
  },
  mixins: [kvpKeysMixin('call_channel'), kvpKeysMixin('mr_call_purpose')],
  data() {
    return {
      productList: [],
      saveLoading: false,
      tabIndex: 0,
      formState: {
        call_product_id: [],
        call_time: new Date(),
        call_period: '',
        feedback_segment_ladder_changed: '',
        feedback_call_object_meet: '',
        co_call_description: '',
        call_description: '',
        physician_id: '',
        call_purpose: '',
        call_channel: '',
        crm_call_id: '',
        crm_status: '',
      },
      feedbackNba: null,
      test: '',
      physicianOptions: [],
      physician: null,
      contextMenuActions: [{text: '删除', disabled: true}],
      timePeriods: ['上午', '下午', '全天'],
      showAddExpense: false,
      showCostProportion: false,
      costProportion: [],
      callBudget: 0,
      defDinerAmount: 0,
      defCall_products: [],
    };
  },
  watch: {
    'formState.call_product_id': function (newVal, oldVal) {
      const res = this.productList.filter((item) =>
        newVal.includes(item.product_id),
      );
      this.costProportion = res;
      this.defCall_products = this.formState.call_products;
      if (newVal.length > 1) {
        this.showCostProportion = true;
      } else {
        this.showCostProportion = false;
      }
    },
    'formState.physician_id': function (newVal) {
      console.log('physician_id', newVal);
    },
  },
  computed: {
    is_co_call() {
      return this.isCoVisit ? 1 : 0;
    },
    name() {
      return this.isCoVisit ? '协访' : '拜访';
    },
    title() {
      const action = this.editId ? '编辑' : '新建';
      return action + this.name;
    },
    isCoVisit() {
      return this.$route.query.isCoVisit === '1';
    },
    editId() {
      return this.$route.query?.id;
    },
    fields() {
      return this.isCoVisit ? this.coVisitFields : this.visitFields;
    },
    showContextMenu() {
      return this.$route.query?.id?.length > 0;
    },
    editable() {
      return this.formState?.crm_status !== 'Submitted_vod';
    },
    coVisitFields() {
      return [
        {
          field: 'physician_id',
          label: '拜访客户',
          required: true,
          type: 'doctorSelect',
          options: this.physicianOptions,
        },
        {
          field: 'call_time',
          type: 'date',
          label: '拜访日期',
          required: true,
        },
        {
          field: 'call_period',
          label: '拜访时间',
          type: 'select',
          options: this.timePeriods,
          required: true,
        },
        {
          field: 'co_call_coach_user',
          label: '协访同事',
          type: 'singleUserSelect',
          props: {
            fn: fetchVUsers,
          },
        },
        {
          type: 'space',
        },
        {
          field: 'call_channel',
          label: '拜访形式',
          type: 'select',
          options: this.call_channel?.map((item) => item.kvp_key_name),
          required: true,
        },
        {
          field: 'co_call_description',
          label: '协访说明',
          type: 'textarea',
          placeholder: '协访目的或协访结果说明',
        },
      ];
    },
    visitFields() {
      return [
        {
          field: 'physician_id',
          label: '拜访客户',
          required: true,
          type: 'doctorSelect',
          options: this.physicianOptions,
          onChange: (data) => {
            this.fetchProductList(data);
          },
          onGet: (data) => {
            console.log('cnm--->', data, this.$refs.addExpense.doctors);
            if (this.$refs.addExpense.doctors.length === 0) {
              this.$refs.addExpense.doctors = data.map((item) => {
                return {
                  physician_id: item.physician_id,
                  physician_name: item.physician_name,
                  department_name: item.department_name,
                  institution_id: item.institution_id,
                  institution_name: item.institution_name,
                  professional_title: item.professional_title,
                };
              });
            }
          },
        },
        // {
        //   field: 'call_location',
        //   label: '拜访地点',
        //   required: true,
        //   type: 'select',
        //   options: ['院内'],
        // },
        {
          field: 'call_time',
          type: 'date',
          label: '拜访日期',
          required: true,
        },
        {
          field: 'call_period',
          label: '拜访时间',
          type: 'select',
          options: this.timePeriods,
          required: true,
        },
        {
          field: 'call_channel',
          label: '拜访形式',
          type: 'select',
          options: this.call_channel.map((item) => item.kvp_key_name),
          required: true,
        },
        {
          type: 'space',
        },
        {
          field: 'call_purpose',
          label: '拜访目标',
          type: 'select',
          options: this.mr_call_purpose.map((item) => item.kvp_key_name),
          required: true,
        },
        {
          field: 'call_product_id',
          label: '涉及产品',
          type: 'checkbox',
          options: this.productList.map((item) => ({
            text: item.product_name,
            value: item.product_id,
          })),
        },
      ];
    },
  },
  mounted() {
    const targetPhysicianId = this.$route.query?.physician_id;
    if (targetPhysicianId) {
      this.formState.physician_id = targetPhysicianId;
      this.fetchProductList(targetPhysicianId).then(() => {
        if (this.editId) {
          this.fetchOnePhysicianMrCall();
        }
      });
    } else if (this.editId) {
      this.fetchOnePhysicianMrCall();
    }

    const nbaId = this.$route.query?.nba_id;
    if (nbaId) {
      this.formState.nba_execution_id = nbaId;
    }

    this.getCallBudget();
  },
  methods: {
    async fetchProductList(physician_id, setDefaultValue = true) {
      const params = {
        user_id: getLocalStorage('user_id'),
        physician_id,
      };
      const res = await getUserPhysicianProduct(params);
      this.productList = res.value;

      if (setDefaultValue) {
        res.value.forEach((item) => {
          if (item.product_name === 'Brintellix') {
            this.formState['call_product_id'] = [item.product_id];
          }
        });
      }

      return res;
    },
    handleChange(field, value) {
      console.log(field, value, 'field value');
      this.formState[field] = value;
    },
    async fetchOnePhysicianMrCall() {
      const {physician, ...res} = await fetchOnePhysicianMrCall(this.editId);
      Object.assign(this.formState, res);
      if (physician) {
        await this.fetchProductList(physician?.physician_id, false);
      }
      this.physician = physician;
      this.formState.call_product_id = JSON.parse(res.call_products).map(
        (item) => item.product_id,
      );
      this.formState.co_call_coach_user = {
        user_id: this.formState.co_call_coach_user_id,
        user_name: this.formState.co_call_coach_user_name,
        territory_id: this.formState.co_call_coach_territory_id,
      };

      // this.formState.call_products = res.call_products;
      this.defDinerAmount = res.diner_amount;
      if (this.formState.crm_status === 'Submitted_vod') {
        this.contextMenuActions[0].disabled = true;
      } else {
        this.contextMenuActions[0].disabled = false;
      }

      this.showAddExpense = this.formState.is_diner;

      this.showCostProportion = JSON.parse(res.call_products).length > 1;
      // this.costProportion = JSON.parse(res.call_products);
      console.log('JSON.parse(res.call_products)', this.costProportion);
      // let callDate = new Date(this.formState.call_time);
      // let today = new Date();
      // today.setHours(0, 0, 0, 0);
      // if (callDate > today) {
      //   console.log('future');
      // }
    },
    async getCallBudget() {
      const budgetRes = await getGetMRCallBudget({
        call_user_id: getLocalStorage('user_id'),
        call_time: this.formState.call_time,
      });
      if (budgetRes.isSuccess) {
        if (budgetRes.data === -1) {
          this.callBudget = '不允许报销';
        } else if (budgetRes.data === -2) {
          this.callBudget = '无限额';
        } else {
          this.callBudget = budgetRes.data;
        }
      }
    },
    async save() {
      const {physician, ...formState} = this.formState;

      if (!validateFields(this.fields, formState)) return;
      this.saveLoading = true;
      let params = {
        ...formState,
        is_co_call: this.is_co_call,
        call_user_id: getLocalStorage('user_id'),
        call_user_name: getLocalStorage('user_name'),
        call_territory_id: getLocalStorage('territory_id'),
      };
      // CRM Status
      // if (!this.formState.crm_status) {
      //   if (this.formState.call_time)
      // }
      if (this.is_co_call) {
        this.formState.co_call_coach_user_id =
          this.formState.co_call_coach_user.user_id;
        this.formState.co_call_coach_user_name =
          this.formState.co_call_coach_user.user_name;
        this.formState.co_call_coach_territory_id =
          this.formState.co_call_coach_user.territory_id;
      }
      delete params.co_call_coach_user;
      if (this.feedbackNba) {
        params.feedback_physician_ladder_nba_id =
          this.feedbackNba.physician_ladder_nba_id;
        params.feedback_physician_ladder_nba_details = JSON.stringify(
          this.feedbackNba,
        );
        console.log('has nba', params);
      }
      // 校验是否填写餐费 start
      const expense = this.$refs.addExpense;
      if (this.showAddExpense) {
        // 判断餐费预算是否超过300标准及YTD余额
        const totalPeople = expense.doctors.length + expense.colleagues.length;
        if (totalPeople && Number(expense.diner_amount) / totalPeople > 300) {
          Toast('预计餐费不能超过人均300标准');
          this.saveLoading = false;
          return;
        }
        if (Number(expense.diner_amount) > this.callBudget) {
          Toast('预计餐费不能超过YTD餐费余额');
          this.saveLoading = false;
          return;
        }
        // 判断products是否为100%
        if (this.showCostProportion) {
          const allNumber = expense.costProportionSubmit.reduce(
            (acc, curr) => Number(acc) + Number(curr.cost_proportion),
            0,
          );
          console.log('allNumber---->', allNumber);
          if (allNumber !== 100) {
            Toast('产品比例总和必须为100%');
            this.saveLoading = false;
            return;
          }
        } else {
          // 仅一个产品，cost_proportion设为100
          expense.costProportionSubmit.forEach(item => {
            item.cost_proportion = 100;
          });
        }
        let data = {};
        data.diner_amount = Number(expense.diner_amount);
        data.diner_physicians = expense.doctors;
        data.diner_users = expense.colleagues;
        data.call_products = expense.costProportionSubmit;
        data.is_diner = this.showAddExpense;
        console.log('expense--->', data);
        params = {...params, ...data};
      } else {
        let data = {};
        data.is_diner = this.showAddExpense;
        data.call_products = expense.costProportionSubmit.map((item) => {
          return {
            product_id: item.product_id,
            product_name: item.product_name,
          };
        });
        params = {...params, ...data};
      }
      // 校验是否填写餐费 end
      try {
        // 保存CRM call
        // 是否已经有crm_call_id
        var crmRes;

        if (this.formState.physician_mr_call_id) {
          // Update
          crmRes = await updateCRMCall(params);
          console.log('crm call update result', crmRes);
        } else {
          // Create
          crmRes = await createCRMCall(params);
          console.log('crm call create result', crmRes);
        }

        if (crmRes && crmRes.isSuccess) {
          Toast(this.editId ? '修改成功' : '创建成功');
          this.saveLoading = false;
          this.$router.go(-1);
        } else {
          this.$toast.fail(
            {
              message: '保存失败' + (crmRes.message ? '\n' + crmRes.message : ''),
              duration: 4000,
              className: 'fail-toast',
              icon: 'cross',
            }
          );
          this.saveLoading = false;
        }
      } catch (err) {
        this.$toast.fail(
          {
            message: '保存失败',
            icon: 'cross',
          }
        );
        this.saveLoading = false;
      }
    },
    handleAddSuggestion(item) {
      this.tabIndex = 1;
      this.$refs.insightEditList.addInsightForm({
        insight_question: item,
      });
    },
    contextMenuFn(action) {
      if (action === '删除') {
        Dialog.confirm({
          message: '是否删除这条记录',
          beforeClose: this.beforeClose,
        });
      }
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        this.saveLoading = true;
        if (this.editId) {
          deleteCRMCall({
            physician_mr_call_id: this.editId,
          })
            .then((res) => {
              if (res.isSuccess) {
                this.$toast.success('删除成功');
                setTimeout(() => {
                  this.$router.go(-1);
                }, 100);
                this.saveLoading = false;
                done();
              } else {
                this.$toast.fail(
                  {
                    message: '删除失败' + (res.message ? '\n' + res.message : ''),
                    duration: 4000,
                    className: 'fail-toast',
                    icon: 'cross',
                  }
                );
                this.saveLoading = false;
                done();
              }
            })
            .catch((err) => {
              console.log(err);
              this.$toast.fail('删除失败');
              this.saveLoading = false;
              done();
            });

          // deleteMrCall(this.editId).then((res) => {
          //   if (res?.value === this.editId) {
          //     this.$toast.success("删除成功");
          //     setTimeout(() => {
          //       this.$router.go(-1);
          //     }, 100);
          //   } else {
          //     this.$toast.fail("删除失败，请重试");
          //   }
          //   this.saveLoading = false;
          //   done();
          // }).catch((err) => {
          //   console.log(err);
          //   this.$toast.fail("删除失败，请重试");
          //   this.saveLoading = false;
          //   done();
          // });
        } else {
          this.$toast.fail('删除失败');
          this.saveLoading = false;
          done();
        }
      } else if (action === 'cancel') {
        done();
      }
    },
    nbaLoaded(nba) {
      this.feedbackNba = nba;
    },
    deleteDiner() {
      this.showAddExpense = false;
      this.formState.is_diner = false;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .van-tab--active {
  color: #1989fa;
}
.question {
  font-size: 14px;
  text-align: left;
}
.space {
  text-align: left;
}
.space > button {
  width: 100px;
}
.space > button ~ button {
  margin-left: 10px;
}
</style>
<style>
.fail-toast {
  width: 200px;
}
</style>
