<template>
  <van-popup v-model="popupVisible" position="bottom">
    <div class="container" style="max-height: 60vh; overflow: auto">
      <div>
        <AsyncList :fn="fetchVUserMsls" :page-size="20">
          <template v-slot="{list, refresh}">
            <div
              style="
                padding: 16px 0;
                font-size: 16px;
                position: sticky;
                top: 0;
                z-index: 99;
                background-color: white;
              "
            >
              <div class="text-center font-bold flex justify-between">
                <div class="van-picker__cancel" @click="popupVisible = false">
                  取消
                </div>
                <div class="van-picker__confirm" @click="ok(list)">确认</div>
              </div>
              <div>
                <van-search
                  v-model="searchKey"
                  :clearable="false"
                  type="search"
                  placeholder="搜索"
                  @search="refresh"
                  @input="refresh"
                />
              </div>
            </div>
            <component
              :is="multiple ? 'van-checkbox-group' : 'van-radio-group'"
              v-model="selected"
            >
              <van-cell-group>
                <van-cell
                  v-for="(item, index) in list"
                  :key="index"
                  @click="toggle(item, index)"
                >
                  <template #title>
                    <span style="font-size: 16px">{{
                      `${item.user_name}`
                    }}</span>
                    <span
                      style="font-size: 12px; color: #999; margin-left: 10px"
                      >{{ item.position }}</span
                    >
                  </template>
                  <template #right-icon>
                    <van-checkbox
                      v-if="multiple"
                      ref="checkboxes"
                      :name="item[compareKey]"
                    />
                    <van-radio v-else :name="item" />
                  </template>
                </van-cell>
              </van-cell-group>
            </component>
          </template>
        </AsyncList>
      </div>
    </div>
  </van-popup>
</template>

<script>
import Vue from 'vue';
import {
  Cell,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Search,
} from 'vant';
import AsyncList from '@/components/AsyncList.vue';
import {fetchMealCompanion} from '@/api/kol';
import MultiSelectDisplay from './MultiSelectDisplay.vue';
import {
  getLocalStorage,
} from '@/utils/index';

Vue.use(Search);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Radio);
Vue.use(RadioGroup);

export default {
  components: {
    AsyncList,
    MultiSelectDisplay,
  },
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    fn: Function,
    type: String,
    showPopup: {
      type: Boolean,
      default: false,
    },
    listValue: null,
  },
  data() {
    return {
      list: [],
      selected: [],
      compareKey: 'user_id',
      searchKey: '',
    };
  },
  computed: {
    popupVisible: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    showList() {
      const list = Array.isArray(this.listValue)
        ? this.listValue
        : [this.listValue].filter(Boolean);

      return list.map((item) => item.user_name);
    },
  },
  watch: {
    /* eslint-disable */
    listValue() {
      if (this.multiple) {
        this.selected = this.multiple
          ? this.listValue?.map((item) => item[this.compareKey]) || []
          : this.listValue;
      }
    },
  },
  methods: {
    fetchVUserMsls(pagination) {
      const territoryType = getLocalStorage('territory_type');
      const fn = this.fn || fetchMealCompanion;
      return fn(this.searchKey, territoryType, pagination);
    },
    toggle(item, index) {
      this.multiple
        ? this.$refs.checkboxes[index].toggle()
        : (this.selected = this.selected === item ? undefined : item);
    },
    ok(list) {
      console.log('this.selected--->', this.selected);
      this.popupVisible = false;
      /* eslint-disable */
      this.$emit(
        'ok',
        this.multiple
          ? this.selected.map((item) =>
              list.find((listItem) => listItem[this.compareKey] === item),
            )
          : this.selected,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.container ::v-deep .van-cell__title {
  text-align: left;
}

.container ::v-deep .van-cell {
  display: flex !important;
  align-items: center;
}

::v-deep .van-cell__value {
  border: none !important;
  margin: none !important;
}
</style>
