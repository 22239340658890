<template>
  <div class="parting">
    <div>
      <div>
        <div v-if="hcpLadder && hcpLadderName" class="parting-type-action">
          <div class="action-title">
            <div class="title-flag"></div>
            <span class="title-text">当前观念阶段</span>
          </div>
          <div>
            <div class="action-plan">
              <div>
                <p class="action-highlight">
                  <span
                    v-if="hcpLadderInvalid"
                    style="font-size: 16px; color: #cc4b47"
                  >
                    请再次确认医生所处的认可阶段
                  </span>
                  <span v-else>
                    {{ hcpLadderName }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          v-else
          style="
          font-size: 14px;
          text-align: left;
          color: #7f7f81;
          padding-left: 0px;"
        >
          <span>
            尚未完成医生分型，请前往<a style="color: #1989fa;" :href="'/hcp/detail?physician_id=' + physician_id">HCP360画像</a>进行识别
          </span>
        </div>

        <div v-if="hcpNextLadderName" class="parting-type-action">
          <div class="action-title">
            <div class="title-flag"></div>
            <span class="title-text">下一步推进观念阶段</span>
          </div>
          <div>
            <div class="action-plan">
              <div>
                <p class="action-highlight">
                  <span>{{ hcpNextLadderName }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div v-if="nbaDirections.length" class="parting-type-action">
        <div class="action-title">
          <div class="title-flag"></div>
          <span class="title-text">行动方向</span>
        </div>
        <div>
          <div class="action-plan">
            <div v-for="(item, index) in nbaDirections" :key="index">
              <p class="action-text">
                <span class="action-flag"></span>
                {{ item }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="nbaKeyMessages.length" class="parting-type-action">
        <div class="action-title">
          <div class="title-flag"></div>
          <span class="title-text">关键信息</span>
        </div>
        <div>
          <div class="action-plan">
            <div v-for="(item, index) in nbaKeyMessages" :key="index">
              <p class="action-text flex">
                <span>
                  <span class="action-flag"></span>
                  {{ item }}
                </span>
                <span>
                  <img
                    src="@/assets/lundbeck/NBA/preview.png"
                    alt=""
                    width="26"
                    style="margin-left: 4px;"
                    @click="previewImage(nbaSlides[index])"
                  />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <!-- 医生分型 -->
      <van-dialog
        v-model="physicianSegDialogShow"
        title="医生分型问答"
        theme="round-button"
        confirm-button-color="#0062b9"
        :before-close="beforePhysicianSegClose"
      >
        <div class="diglog-close" @click="closeDialog()">×</div>
        <div class="diglog-questions">
          <div class="question">
            <p class="question-text">
              1. 医生认知好不好推
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="hcpSegQuestionAnswers.firstAnswer"
              direction="horizontal"
              class="answer"
            >
              <van-radio name="好推" style="margin-right: 30%">好推</van-radio>
              <van-radio name="不好推"> 不好推 </van-radio>
            </van-radio-group>
            <p v-if="hcpSegQuestionAnswers.firstTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
          <div class="question">
            <p class="question-text">
              2. 医生对于客户疾病观念的描述（功能恢复目标）<span
                style="color: #cc4b47"
              >*</span><span style="color: #0362b9">【多选题】</span>
            </p>
            <van-checkbox-group v-model="hcpSegQuestionAnswers.secondAnswer">
              <van-checkbox
                name="1"
                shape="square"
                class="answer-checkbox"
                @click="hcpSegSecondQuestionOthersClicked()"
              >
                他期望患者恢复到病前状态，问到患者工作学习社会生活的恢复情况时，能具体描述是不是恢复到了病前状态
              </van-checkbox>
              <van-checkbox
                name="2"
                shape="square"
                class="answer-checkbox"
                @click="hcpSegSecondQuestionOthersClicked()"
              >
                他对快感缺失患者，会想一些办法（减量，调整治疗方案），尽量能帮助他们恢复
              </van-checkbox>
              <van-checkbox
                name="3"
                shape="square"
                class="answer-checkbox"
                @click="hcpSegSecondQuestionOthersClicked()"
              >
                他对疲乏低动力患者，会想一些办法（减量，调整治疗方案），尽量能帮助他们恢复
              </van-checkbox>
              <van-checkbox
                name="4"
                shape="square"
                class="answer-checkbox"
                @click="hcpSegSecondQuestionOthersClicked()"
              >
                他对情感迟钝患者，会想一些办法（减量，调整治疗方案），尽量能帮助他们恢复
              </van-checkbox>
              <van-checkbox
                name="5"
                shape="square"
                class="answer-checkbox"
                @click="hcpSegSecondQuestionNoneClicked()"
              >
                以上都不是
              </van-checkbox>
            </van-checkbox-group>
            <p v-if="hcpSegQuestionAnswers.secondTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
        </div>
      </van-dialog>
    </div>

    <div>
      <!-- 医生所处阶段 -->
      <van-dialog
        v-model="physicianLadderDialogShow"
        title="医生所处阶段问答"
        theme="round-button"
        confirm-button-color="#0062b9"
        :before-close="beforePhysicianLadderClose"
      >
        <div class="diglog-close" @click="closeDialog()">×</div>
        <div class="diglog-questions">
          <div class="question">
            <p class="question-text">
              1. 医生是否处方过心达悦
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="hcpLadderQuestionAnswers.firstAnswer"
              direction="horizontal"
              class="answer"
            >
              <van-radio name="是" style="margin-right: 30%">是</van-radio>
              <van-radio name="否">否</van-radio>
            </van-radio-group>
            <p v-if="hcpLadderQuestionAnswers.firstTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
          <div class="question">
            <p class="question-text">
              2. 医生是否建立了心达悦一定的用药感受和用药习惯
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="hcpLadderQuestionAnswers.secondAnswer"
              direction="horizontal"
              class="answer"
              :disabled="
                !hcpLadderQuestionAnswers.firstAnswer ||
                  hcpLadderQuestionAnswers.firstAnswer === '否'
              "
            >
              <van-radio name="是" style="margin-right: 30%">是</van-radio>
              <van-radio name="否">否</van-radio>
            </van-radio-group>
            <p
              v-if="hcpLadderQuestionAnswers.secondTips"
              style="color: #cc4b47"
            >
              这是必答题，请选择答案！
            </p>
          </div>
        </div>
      </van-dialog>
    </div>

    <div>
      <!-- 医生所处的产品认可阶段 -->
      <van-dialog
        v-model="physicianBehaviorDialogShow"
        title="医生所处产品认可阶段问答"
        theme="round-button"
        confirm-button-color="#0062b9"
        :before-close="beforePhysicianBahaviorClose"
      >
        <div class="diglog-close" @click="closeDialog()">×</div>
        <div class="diglog-questions">
          <div class="question">
            <p class="question-text">
              1. 医生现在的常见语言
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="hcpBehaviorQuestionAnswers.firstAnswer"
              direction="horizontal"
            >
              <van-radio
                name="1"
                class="answer-checkbox"
              >心达悦太贵了，已经很帮你了</van-radio>
              <van-radio
                name="2"
                class="answer-checkbox"
              >心达悦适合高功能需求人群，但说不出病人的转归情况</van-radio>
              <van-radio
                name="3"
                class="answer-checkbox"
              >心达悦有优势，学习状态恢复（听课、背单词），成绩优秀（考试分数），家长感激</van-radio>
              <van-radio
                name="4"
                class="answer-checkbox"
              >心达悦能够让患者完全恢复，发自内心的骄傲</van-radio>
            </van-radio-group>
            <p
              v-if="hcpBehaviorQuestionAnswers.firstTips"
              style="color: #cc4b47"
            >
              这是必答题，请选择答案！
            </p>
          </div>
          <div class="question">
            <p class="question-text">
              2. 医生现在的主要患者类型
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="hcpBehaviorQuestionAnswers.secondAnswer"
              direction="horizontal"
            >
              <van-radio
                name="1"
                class="answer-checkbox"
              >有钱的，安全需求高的</van-radio>
              <van-radio
                name="2"
                class="answer-checkbox"
              >初诊的梅栋丽，初诊的高知秦思光</van-radio>
              <van-radio
                name="3"
                class="answer-checkbox"
              >换药的梅栋丽，换药的高知秦思光</van-radio>
              <van-radio
                name="4"
                class="answer-checkbox"
              >换药的普通秦思光</van-radio>
            </van-radio-group>
            <p
              v-if="hcpBehaviorQuestionAnswers.secondTips"
              style="color: #cc4b47"
            >
              这是必答题，请选择答案！
            </p>
          </div>
          <div class="question">
            <p class="question-text">
              3. 对于上述常见语言和患者类型的认可阶段：
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="hcpBehaviorQuestionAnswers.thirdAnswer"
              direction="horizontal"
              class="answer"
            >
              <van-radio name="尝试" style="margin-right: 30%">尝试</van-radio>
              <van-radio name="常规">常规</van-radio>
            </van-radio-group>
            <p
              v-if="hcpBehaviorQuestionAnswers.thirdTips"
              style="color: #cc4b47"
            >
              这是必答题，请选择答案！
            </p>
          </div>
        </div>
      </van-dialog>
    </div>

    <swipeDialog v-if="pdfLink" backtxt="返回" :title="'预览'" @closeFn="closePdfFn">
      <pdf v-show="pdfLink" :src="pdfLink" />
    </swipeDialog>

    <div>
      <!-- 拒绝 -->
      <!-- <van-popover
        v-model="nbaRejectDialogShow"
        :before-close="beforePhysicianBahaviorClose"
        :round="true"
        :overlay="true"
        style="width: 60%;"
      >
        <div style="padding: 10px;">
          <img src="@/assets/lundbeck/NBA/_mock-reject-dialog.png" alt="" width="100%" />
        </div>
      </van-popover> -->
    </div>
  </div>
</template>

<script>
const SEGMENTS = [null, '全面症状', '功能恢复', '情感驱动', '信心满满'];
const LADDERS = [
  null,
  [
    '',
    '未处方', // 1
    '安全性 - 尝试', // 2
    '安全性 - 常规', // 3 - 常规1
    '改善认知 - 尝试', // 4 - 尝试2
    '改善认知 - 常规', // 5 - 常规2
    '', // 6 - 尝试3
    '', // 7 - 常规3
    '', // 8 - 尝试4
    '', // 9 - 常规4
    '', // 10 - 足量足疗程
  ],
  [
    '',
    '未处方', // 1
    '安全性 - 尝试', // 2
    '安全性 - 常规', // 3 - 常规1
    '改善认知 - 尝试', // 4 - 尝试2
    '改善认知 - 常规', // 5 - 常规2
    '认知->功能 - 尝试', // 6 - 尝试3
    '认知->功能 - 常规', // 7 - 常规3
    '其他->功能 - 尝试', // 8 - 尝试4
    '其他->功能 - 常规', // 9 - 常规4
    '足量足疗程', // 10 - 足量足疗程
  ],
  [
    '',
    '未处方', // 1
    '安全性 - 尝试', // 2
    '常规', // 3 - 常规1
    '', // 4 - 尝试2
    '', // 5 - 常规2
    '', // 6 - 尝试3
    '', // 7 - 常规3
    '', // 8 - 尝试4
    '', // 9 - 常规4
    '', // 10 - 足量足疗程
  ],
  [
    '',
    '未处方', // 1
    '安全性 - 尝试', // 2
    '安全性 - 常规', // 3 - 常规1
    '', // 4 - 尝试2
    '', // 5 - 常规2
    '', // 6 - 尝试3
    '', // 7 - 常规3
    '其他->功能 - 尝试', // 8 - 尝试4
    '其他->功能 - 常规', // 9 - 常规4
    '足量足疗程', // 10 - 足量足疗程
  ],
];
const LADDERS_NEXT = [
  null,
  [
    '',
    '安全性 - 尝试', // 2
    '安全性 - 常规', // 3 - 常规1
    '改善认知 - 尝试', // 4 - 尝试2
    '改善认知 - 常规', // 5 - 常规2
    '改善认知 - 尝试', // 6 - 尝试3
    '改善认知 - 尝试', // 7 - 常规3
    '改善认知 - 尝试', // 8 - 尝试4
    '改善认知 - 尝试', // 9 - 常规4
    '改善认知 - 尝试', // 10
    '改善认知 - 尝试',
  ],
  [
    '',
    '安全性 - 尝试', // 2
    '安全性 - 常规', // 3 - 常规1
    '改善认知 - 尝试', // 4 - 尝试2
    '改善认知 - 常规', // 5 - 常规2
    '认知->功能 - 尝试', // 6 - 尝试3
    '认知->功能 - 常规', // 7 - 常规3
    '其他->功能 - 尝试', // 8 - 尝试4
    '其他->功能 - 常规', // 9 - 常规4
    '足量足疗程', // 10
    '足量足疗程',
  ],
  [
    '',
    '未处方', // 1
    '安全性 - 尝试', // 2
    '常规', // 3 - 常规1
    '常规', // 4 - 尝试2
    '常规', // 5 - 常规2
    '常规', // 6 - 尝试3
    '常规', // 7 - 常规3
    '常规', // 8 - 尝试4
    '常规', // 9 - 常规4
    '常规', // 10
  ],
  [
    '',
    '安全性 - 尝试', // 2
    '安全性 - 常规', // 3 - 常规1
    '其他->功能 - 尝试', // 4 - 尝试2
    '其他->功能 - 尝试', // 5 - 常规2
    '其他->功能 - 尝试', // 6 - 尝试3
    '其他->功能 - 尝试', // 7 - 常规3
    '其他->功能 - 尝试', // 8 - 尝试4
    '其他->功能 - 常规', // 9 - 常规4
    '足量足疗程', // 10
    '足量足疗程', // 10
  ],
];
import {getLocalStorage} from '@/utils';
import * as py from '@/api/Physicians';
import {
  Button,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Popover,
  ImagePreview,
} from 'vant';
import swipeDialog from "@/views/layout/swipeDialog";
import pdf from "vue-pdf";
export default {
  name: 'Brintellix',
  components: {
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    vanCheckboxGroup: CheckboxGroup,
    vanCheckbox: Checkbox,
    pdf,
    swipeDialog,
  },
  props: {
    physician_id: String,
    productId: {
      type: String,
      default() {
        return 'Brintellix_000001';
      },
    },
  },
  data() {
    return {
      physicianSegDialogShow: false,
      physicianLadderDialogShow: false,
      physicianBehaviorDialogShow: false,
      nbaRejectDialogShow: false,
      hcpSegQuestionAnswers: {
        firstAnswer: null,
        firstTips: false,
        secondAnswer: [],
        secondTips: false,
      },
      hcpLadderQuestionAnswers: {
        firstAnswer: null,
        firstTips: false,
        secondAnswer: null,
        secondTips: false,
      },
      hcpBehaviorQuestionAnswers: {
        firstAnswer: null,
        firstTips: false,
        secondAnswer: null,
        secondTips: false,
        thirdAnswer: null,
        thirdTips: false,
      },
      canEditStatus: false,
      hcpSeg: 0,
      hcpSubSeg: '',
      hcpBahavior: 0,
      hcpLadder: 0,
      hcpLadderInvalid: false,
      hcpSegName: '',
      hcpLadderName: '',
      hcpNextLadderName: '',
      nbaDirections: [],
      nbaKeyMessages: [],
      nbaSlides: [],
      pdfLink: '',
      hcpSegImages: [
        null,
        require('@/assets/lundbeck/NBA/hcp-seg-brintellix-1.png'),
        require('@/assets/lundbeck/NBA/hcp-seg-brintellix-2.png'),
        require('@/assets/lundbeck/NBA/hcp-seg-brintellix-3.png'),
        require('@/assets/lundbeck/NBA/hcp-seg-brintellix-4.png'),
      ],
      hcpLadderImages: [
        null,
        [
          null,
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-1-1.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-1-2.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-1-3.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-1-4.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-1-5.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-1-invalid.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-1-invalid.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-1-invalid.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-1-invalid.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-1-invalid.png'),
        ],
        [
          null,
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-2-1.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-2-2.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-2-3.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-2-4.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-2-5.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-2-6.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-2-7.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-2-8.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-2-9.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-2-10.png'),
        ],
        [
          null,
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-3-1.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-3-2.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-3-3.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-3-invalid.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-3-invalid.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-3-invalid.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-3-invalid.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-3-invalid.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-3-invalid.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-3-invalid.png'),
        ],
        [
          null,
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-4-1.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-4-2.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-4-3.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-4-invalid.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-4-invalid.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-4-invalid.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-4-invalid.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-4-4.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-4-5.png'),
          require('@/assets/lundbeck/NBA/hcp-ladder-brintellix-4-6.png'),
        ],
      ],
    };
  },
  watch: {
    physician_id: 'getPType',
    productId: 'getPType',
  },
  mounted() {
    if (getLocalStorage('territory_type') === 'MR') {
      this.canEditStatus = true;
    }
    this.getPType();
  },
  methods: {
    getPType() {
      this.hcpSeg = 0;
      this.hcpSubSeg = '';
      this.hcpLadder = 0;
      this.hcpLadderName = '';
      this.hcpNextLadderName = '';
      this.nbaDirections = [];
      this.nbaKeyMessages = [];
      this.nbaSlides = [];

      py.getLatestPhysicianSegment({
        physician_id: this.physician_id,
        product_id: 'Brintellix_000001',
      }).then((res) => {
        if (res.value && res.value.length) {
          this.hcpSeg = res.value[0].segment_index;
          this.hcpSubSeg = res.value[0].sub_segment;
          py.getLatestPhysicianLadder({
            physician_id: this.physician_id,
            product_id: 'Brintellix_000001',
          }).then((res) => {
            if (res.value && res.value.length) {
              this.hcpLadder = res.value[0].ladder_index;
              this.hcpLadderName = res.value[0].ladder_name;
              // Read NBA
              this.fetchNba();
            }
          });
        }
      });
    },
    fetchNba() {
      this.hcpNextLadderName = '';
      this.nbaDirections = [];
      this.nbaKeyMessages = [];
      this.nbaSlides = [];
      this.hcpLadderInvalid = false;
      if (!LADDERS[this.hcpSeg][this.hcpLadder]) {
        this.hcpLadderInvalid = true;
      }
      py.getPhysicianLadderNbas({
        product_id: 'Brintellix_000001',
        segment_index: this.hcpSeg,
        sub_segment: this.hcpSubSeg,
        ladder_index: this.hcpLadderInvalid ? 'INVALID' : this.hcpLadder,
      }).then((res) => {
        if (res.value && res.value.length > 0) {
          let item = res.value[0];
          this.hcpNextLadderName = item.next_ladder_name;
          if (item.directions) {
            this.nbaDirections = item.directions.split('|');
          }
          if (item.key_messages) {
            this.nbaKeyMessages = item.key_messages.split('|');
          }
          if (item.slides) {
            this.nbaSlides = item.slides.split('|');
          }
          this.$emit('nbaLoaded', item);
        }
      });
    },
    openPhysicianSegDialog() {
      this.physicianSegDialogShow = true;
    },
    openPhysicianLadderDialog() {
      this.physicianLadderDialogShow = true;
    },
    openPhysicianBehaviorDialog() {
      this.physicianBehaviorDialogShow = true;
    },
    openRejectDialog() {
      this.nbaRejectDialogShow = true;
    },
    hcpSegSecondQuestionOthersClicked() {
      if (
        this.hcpSegQuestionAnswers.secondAnswer.indexOf('1') >= 0 ||
        this.hcpSegQuestionAnswers.secondAnswer.indexOf('2') >= 0 ||
        this.hcpSegQuestionAnswers.secondAnswer.indexOf('3') >= 0 ||
        this.hcpSegQuestionAnswers.secondAnswer.indexOf('4') >= 0
      ) {
        if (this.hcpSegQuestionAnswers.secondAnswer.indexOf('5') >= 0) {
          this.hcpSegQuestionAnswers.secondAnswer.splice(
            this.hcpSegQuestionAnswers.secondAnswer.indexOf('5'),
            1,
          );
        }
      }
    },
    hcpSegSecondQuestionNoneClicked() {
      if (this.hcpSegQuestionAnswers.secondAnswer.indexOf('5') >= 0) {
        this.hcpSegQuestionAnswers.secondAnswer = ['5'];
      }
    },
    beforePhysicianSegClose(action, done) {
      if (action === 'confirm') {
        if (this.ajaxStatus) {
          return;
        }
        if (this.hcpSegQuestionAnswers.firstAnswer == null) {
          this.hcpSegQuestionAnswers.firstTips = true;
          done(false);
          return;
        }
        if (this.hcpSegQuestionAnswers.secondAnswer == null) {
          this.hcpSegQuestionAnswers.secondTips = true;
          done(false);
          return;
        }
        // 分型判断：
        if (this.hcpSegQuestionAnswers.firstAnswer === '好推') {
          if (this.hcpSegQuestionAnswers.secondAnswer.indexOf('5') >= 0) {
            this.hcpSeg = 1;
          } else {
            this.hcpSeg = 2;
          }
        } else {
          // 不好推
          if (this.hcpSegQuestionAnswers.secondAnswer.indexOf('5') >= 0) {
            this.hcpSeg = 3;
          } else {
            this.hcpSeg = 4;
          }
        }
        if (
          this.hcpSegQuestionAnswers.secondAnswer.length === 1 &&
          this.hcpSegQuestionAnswers.secondAnswer.indexOf('1') === 0
        ) {
          this.hcpSubSeg = 'only1';
        } else if (
          this.hcpSegQuestionAnswers.secondAnswer.length === 1 &&
          this.hcpSegQuestionAnswers.secondAnswer.indexOf('5') === 0
        ) {
          this.hcpSubSeg = 'only5';
        } else {
          this.hcpSubSeg = 'others';
        }

        this.hcpLadder = null;
        let uName = getLocalStorage('user_name');
        let params = {
          physician_id: this.$route.query.physician_id,
          content: JSON.stringify(this.hcpSegQuestionAnswers),
          user_id: getLocalStorage('user_id'),
          territory_id: getLocalStorage('territory_id'),
          create_time: Date.parse(new Date()),
          product_id: this.productId,
          segment_index: this.hcpSeg.toString(),
          segment_name: SEGMENTS[this.hcpSeg],
          sub_segment: this.hcpSubSeg,
          modify_user_id: getLocalStorage('user_id'),
          modify_user_name:
            uName !== 'null' ? uName : getLocalStorage('user_id'),
        };
        this.ajaxStatus = true;
        py.setPhysicianSegment(params).then((res) => {
          if (res.is_success) {
            this.$toast.success('提交成功');
            setTimeout(() => {
              done();
            }, 2);
          } else {
            this.$toast.success('提交失败，请重试');
            done(false);
          }
          this.ajaxStatus = false;
        });
      } else {
        done();
      }
    },
    beforePhysicianLadderClose(action, done) {
      if (action === 'confirm') {
        if (this.ajaxStatus) {
          return;
        }
        if (this.hcpLadderQuestionAnswers.firstAnswer == null) {
          this.hcpLadderQuestionAnswers.firstTips = true;
          done(false);
          return;
        }
        if (
          this.hcpLadderQuestionAnswers.secondAnswer == null &&
          this.hcpLadderQuestionAnswers.firstAnswer === '是'
        ) {
          this.hcpLadderQuestionAnswers.secondTips = true;
          done(false);
          return;
        }
        // 大阶段判断：
        if (this.hcpLadderQuestionAnswers.firstAnswer === '否') {
          this.hcpLadder = 1;
        } else {
          // 处方过
          if (this.hcpLadderQuestionAnswers.secondAnswer === '否') {
            this.hcpLadder = 2; // 尝试
          } else {
            this.hcpLadder = 3; // 常规
          }
        }
      }

      this.hcpLadderName = LADDERS[this.hcpSeg][this.hcpLadder];
      this.hcpNextLadderName = LADDERS_NEXT[this.hcpSeg][this.hcpLadder];

      if (this.hcpLadder < 3) {
        this.savePhysicianLadder();
      }

      done();
      if (this.hcpLadder >= 3) {
        this.openPhysicianBehaviorDialog();
      }
    },
    beforePhysicianBahaviorClose(action, done) {
      if (action === 'confirm') {
        if (this.ajaxStatus) {
          return;
        }
        if (this.hcpBehaviorQuestionAnswers.firstAnswer == null) {
          this.hcpBehaviorQuestionAnswers.firstTips = true;
          done(false);
          return;
        }
        if (this.hcpBehaviorQuestionAnswers.secondAnswer == null) {
          this.hcpBehaviorQuestionAnswers.secondTips = true;
          done(false);
          return;
        }
        if (this.hcpBehaviorQuestionAnswers.thirdAnswer == null) {
          this.hcpBehaviorQuestionAnswers.thirdTips = true;
          done(false);
          return;
        }
      }

      this.savePhysicianLadder();
      done();
    },
    savePhysicianLadder() {
      // 判断所处认可阶段：
      let behaviorFirstAnswer = Number(
        this.hcpBehaviorQuestionAnswers.firstAnswer,
      );
      let behaviorSecondAnswer = Number(
        this.hcpBehaviorQuestionAnswers.secondAnswer,
      );
      let behaviorThirdAnswer =
        this.hcpBehaviorQuestionAnswers.thirdAnswer === '常规' ? 1 : 0;
      this.hcpBahavior = Math.min(behaviorFirstAnswer, behaviorSecondAnswer);
      if (this.hcpBahavior === 1) {
        this.hcpLadder = 2 + behaviorThirdAnswer;
      } else if (this.hcpBahavior === 2) {
        this.hcpLadder = 4 + behaviorThirdAnswer;
      } else if (this.hcpBahavior === 3) {
        this.hcpLadder = 6 + behaviorThirdAnswer;
      } else if (this.hcpBahavior === 4) {
        this.hcpLadder = 8 + behaviorThirdAnswer;
      }

      this.hcpLadderName = LADDERS[this.hcpSeg][this.hcpLadder];
      this.hcpNextLadderName = LADDERS_NEXT[this.hcpSeg][this.hcpLadder];
      console.log(
        'ladder index:',
        this.hcpLadder,
        LADDERS[this.hcpSeg][this.hcpLadder],
      );
      console.log('next ladder:', LADDERS_NEXT[this.hcpSeg][this.hcpLadder]);

      // 判断是否有效
      this.hcpLadderInvalid = false;
      if (!LADDERS[this.hcpSeg][this.hcpLadder]) {
        this.hcpLadderInvalid = true;
      }
      console.log(
        'invalid: ',
        this.hcpLadderInvalid,
        this.hcpSeg,
        this.hcpLadder,
      );

      let uName = getLocalStorage('user_name');
      let params = {
        physician_id: this.$route.query.physician_id,
        content: JSON.stringify(this.hcpLadderQuestionAnswers),
        user_id: getLocalStorage('user_id'),
        territory_id: getLocalStorage('territory_id'),
        create_time: Date.parse(new Date()),
        product_id: this.productId,
        ladder_index: this.hcpLadder.toString(),
        ladder_name: LADDERS[this.hcpSeg][this.hcpLadder],
        modify_user_id: getLocalStorage('user_id'),
        modify_user_name: uName !== 'null' ? uName : getLocalStorage('user_id'),
      };
      this.ajaxStatus = true;
      py.setPhysicianLadder(params).then((res) => {
        if (res.is_success) {
          this.$toast.success('提交成功');
        } else {
          this.$toast.success('提交失败，请重试');
        }
        this.ajaxStatus = false;
      });

      this.fetchNba();
    },
    previewImage(slides) {
      console.log(slides);
      if (slides.indexOf('.pdf') > 0) {
        this.lookPdfDetail('/resource/nba/brintellix/key-message/' + slides);
      } else {
        let images = [];
        slides.split(',').forEach(image => {
          images.push('/resource/nba/brintellix/key-message/' + image);
        });
        ImagePreview({
          images: images,
          loop: false,
          showIndicators: true,
        });
      }
    },
    enterVisit() {
      this.$router.push({
        path: `/hcp/visit-create?physician_id=${this.$route.query.physician_id}`,
      });
    },
    closeDialog() {
      this.physicianSegDialogShow = false;
      this.physicianLadderDialogShow = false;
      this.physicianBehaviorDialogShow = false;
      this.nbaRejectDialogShow = false;
    },
    lookPdfDetail(url) {
      this.$store.dispatch("setDialogStatus", true);
      this.pdfLink = url;
    },
    closePdfFn() {
      this.$store.dispatch("setDialogStatus", false);
      this.pdfLink = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.parting {
  margin-top: 20px;
  .tip-text {
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;
    color: #2c3034;
    opacity: 1;
    margin: 40px auto 20px;
  }
  .tip-button {
    width: 150px;
    height: 36px;
    background: #0062b9;
    opacity: 1;
    border-radius: 500px;
    margin-bottom: 40px;
  }
  .selected-parting {
    display: flex;
    height: 22px;
    margin-bottom: 20px;

    p {
      margin-left: 10px;
      font-size: 16px;
      font-family: Arial;
      font-weight: 700;
      line-height: 22px;
      color: #2c3034;
      text-align: left;
    }
  }
  .diglog-close {
    position: absolute;
    font-size: 30px;
    top: 25px;
    right: 20px;
  }
  .diglog-questions {
    max-height: 480px;
    overflow-y: auto;
    padding: 20px 20px 0 20px;
    .question {
      margin-bottom: 20px;
      .question-text {
        font-size: 14px;
        font-family: Arial;
        font-weight: 700;
        line-height: 1.5;
        color: #2c3034;
        opacity: 1;
        text-align: left;
        margin-bottom: 10px;
      }
      .answer {
        display: flex;
        justify-content: center;
      }
      .answer-checkbox {
        align-items: flex-start;
        margin: 5px;
        ::v-deep .van-checkbox__label,
        ::v-deep .van-radio__label {
          font-size: 14px;
          text-align: left;
        }
      }
    }
  }
  .parting-content {
    .parting-content-x {
      display: flex;
      justify-content: space-around;
      margin-bottom: 10px;
      .parting-content-process {
        display: flex;
        align-items: center;
        width: 50px;
        font-size: 10px;
        font-family: Arial;
        font-weight: 700;
        line-height: 1.2;
        color: #2c3034;
        text-align: left;
      }
      .parting-content-result {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-family: Arial;
        font-weight: 700;
        line-height: 11px;
        color: #0062b9;
      }
    }
    .parting-content-type {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2px 0;
      .parting-content-type-process {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 74px;
        height: 60px;
        background: linear-gradient(46deg, #f4f9fd 0%, #ddefff 100%);
        border-radius: 4px;
        p {
          width: 60px;
          font-size: 10px;
          font-family: Arial;
          font-weight: 400;
          line-height: 1.2;
          color: #2c3034;
          text-align: left;
        }
      }
      .parting-content-type-result {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 74px;
        height: 60px;
        background: linear-gradient(46deg, #e8f4ff 0%, #c4e3ff 100%);
        border-radius: 4px;
        p {
          width: 60px;
          font-size: 10px;
          font-family: Arial;
          line-height: 1.2;
          color: #0062b9;
          text-align: center;
        }
      }
    }
    .parting-line {
      width: 5px;
      height: 0px;
      border: 2px solid #0062b9;
      background-color: #0062b9;
    }
  }
  .current-type {
    padding: 2px;
    background: rgba(255, 255, 255, 0);
    border: 1px solid #ef9c00;
    opacity: 1;
    border-radius: 4px;
  }
  .parting-type-action {
    // margin-top: 20px;
    .title-flag {
      width: 6px;
      height: 14px;
      background: #1b75bc;
      opacity: 1;
      border-radius: 4px;
    }
    .title-text {
      height: 22px;
      font-size: 14px;
      font-family: Arial;
      font-weight: 700;
      color: #2c3034;
      opacity: 1;
      margin-left: 5px;
    }
    .action-title {
      display: flex;
      align-items: baseline;
      height: 20px;
    }
    .action-plan {
      padding: 10px 10px;
    }
    .action-flag {
      display: inline-block;
      width: 6px;
      height: 6px;
      background: #0062b9;
      opacity: 1;
      border-radius: 4px;
      margin: 0 5px 2px 0;
    }
    .action-text {
      font-size: 14px;
      font-family: Arial;
      font-weight: 400;
      color: #2c3034;
      opacity: 1;
      text-align: left;
      line-height: 1.2;
      margin-bottom: 15px;
    }
    .action-highlight {
      font-size: 16px;
      font-family: Arial;
      font-weight: 700;
      color: #0062b9;
      opacity: 1;
      text-align: left;
      line-height: 1.4;
      margin-bottom: 15px;
      .action-flag {
        background: #aaa;
      }
      span {
        font-weight: 700;
      }
    }
    .action-tag {
      color: #3caa45;
    }
    .highlight {
      color: #0062b9;
    }
    .action-progress {
      display: flex;
      align-items: center;
      height: 30px;
      background: rgba(0, 98, 185, 0.08);
      border-radius: 4px;
      margin-bottom: 20px;
      p {
        color: #0062b9;
        font-size: 14px;
        font-family: Arial;
        font-weight: 700;
        margin-left: 10px;
      }
    }
  }
}
.strategy-map {
  .map {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    .map-start {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      height: 40px;
      background: rgba(27, 117, 188, 0.1);
      border-radius: 500px;
      img {
        margin-left: 5px;
      }
    }
    .map-transmit {
      width: 90%;
      background: url('~@/assets/svg/px.svg') no-repeat;
      background-size: 100% auto;
      line-height: 15px;
      img {
        margin: 0 2px;
      }
    }
    .map-lx-blue {
      width: 140px;
      height: 84px;
      background: url('~@/assets/svg/lx_blue.svg') no-repeat;
      background-size: 100% auto;
      line-height: 15px;
      img {
        margin: 0 2px;
      }
    }
    .map-lx-yellow {
      width: 140px;
      height: 84px;
      background: url('~@/assets/svg/lx_yellow.svg') no-repeat;
      background-size: 100% auto;
      line-height: 15px;
      color: #cb8706;
      img {
        margin: 0 2px;
      }
    }
    .right-arrow {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 12px;
      font-family: Arial;
      font-weight: 400;
      line-height: 16px;
      color: #838a95;
    }
    .up-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 12px;
      font-size: 12px;
      font-family: Arial;
      font-weight: 400;
      line-height: 16px;
      color: #838a95;
    }
  }

  .tryed-map {
    .map-transmit {
      background: url('~@/assets/svg/px.svg') no-repeat;
      background-size: 100% auto;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      margin-top: 10px;

      p {
        width: 70%;
      }
      img {
        margin: 0 2px;
      }
    }
    .map-categories {
      display: flex;
      flex-wrap: wrap;
      margin-left: 30px;
      border-left: 1px solid #1b75bc;
      padding-bottom: 70px;
      .map-category {
        display: flex;
        align-items: center;
        height: 150px;
        .map-recipe {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          background: #1b75bc;
          border-radius: 500px;
          font-size: 14px;
          font-family: Arial;
          font-weight: bold;
          line-height: 16px;
          color: #ffffff;
        }
        .map-name {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #00baba;
          border-radius: 500px;
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          line-height: 16px;
          color: #1dafc3;
          z-index: 2;
        }
        .map-category-content {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          width: 200px;
          height: 105px;
          background: url('~@/assets/svg/lx_blue.svg') no-repeat;
          background-size: 100% auto;
          margin-left: -20px;
          p {
            font-size: 14px;
            font-family: Arial;
            font-weight: 400;
            line-height: 16px;
            color: #1dafc3;
            span {
              color: #ef9c00;
              font-weight: bold;
            }
          }
          .map-category-expand-style {
            display: flex;
            justify-content: center;
            .map-category-arrow-down {
              display: inline-block;
              width: 5px;
              height: 5px;
              border-top: 1px solid #838a95;
              border-left: 1px solid #838a95;
              transform: rotate(225deg);
              margin-right: 2px;
              margin-bottom: 2px;
            }
            .map-category-arrow-up {
              display: inline-block;
              width: 5px;
              height: 5px;
              border-top: 1px solid #838a95;
              border-left: 1px solid #838a95;
              transform: rotate(45deg);
              margin-right: 2px;
              margin-bottom: -2px;
            }
            .map-category-expend-btn {
              font-size: 10px;
              color: #838a95;
            }
          }
        }
      }
    }

    .map-category-end {
      display: flex;
      align-items: center;
      height: 150px;
      position: relative;
      left: 0.8rem;
      bottom: 2rem;
      .map-category-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 200px;
        height: 105px;
        background: url('~@/assets/svg/lx_blue.svg') no-repeat;
        background-size: 100% auto;
        p {
          font-size: 12px;
          font-family: Arial;
          font-weight: 400;
          line-height: 16px;
          color: #1dafc3;
          span {
            color: #ef9c00;
            font-weight: bold;
          }
        }
        .map-category-expand-style {
          display: flex;
          justify-content: center;
          margin-top: 10px;
          .map-category-arrow-down {
            display: inline-block;
            width: 5px;
            height: 5px;
            border-top: 1px solid #838a95;
            border-left: 1px solid #838a95;
            transform: rotate(225deg);
            margin-right: 2px;
            margin-bottom: 2px;
          }
          .map-category-arrow-up {
            display: inline-block;
            width: 5px;
            height: 5px;
            border-top: 1px solid #838a95;
            border-left: 1px solid #838a95;
            transform: rotate(45deg);
            margin-right: 2px;
            margin-bottom: -2px;
          }
          .map-category-expend-btn {
            font-size: 10px;
            color: #838a95;
          }
        }
      }
    }

    .emotion-path {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      background: #f5f7f8;
      border-radius: 10px;
      padding: 20px 10px;
      margin-left: 10px;
      .emotion-path-title {
        margin-bottom: 20px;
        font-size: 16px;
        font-family: Arial;
        font-weight: bold;
        color: #0062b9;
      }
      .emotion-path-node-oval {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        background: rgba(27, 117, 188, 0.1);
        border-radius: 500px;
        color: #0062b9;
        img {
          margin-left: 5px;
        }
        span {
          font-weight: 700;
        }
      }

      .emotion-path-node-oval2 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: 60px;
        background: rgba(27, 117, 188, 0.1);
        border-radius: 500px;
        line-height: 20px;
        color: #0062b9;
        img {
          margin: 0 2px;
        }
        span {
          font-weight: 700;
        }
      }
      .emotion-path-node-lx {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 270px;
        height: 90px;
        background: url('~@/assets/svg/long_lx_blue.svg') no-repeat;
        background-size: 100% auto;
        line-height: 15px;
        p {
          color: #1dafc3;
        }
        img {
          margin: 0 2px;
        }
      }
      .emotion-path-px {
        width: 270px;
        height: 45px;
        flex-wrap: wrap;
        background: url('~@/assets/svg/px.svg') no-repeat;
        background-size: 100% auto;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0062b9;
        img {
          margin-left: 5px;
        }
        span {
          font-weight: 700;
        }
      }
    }
  }
}
.diglog-close {
  position: absolute;
  font-size: 30px;
  top: 25px;
  right: 20px;
}
.switch-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
  .switch-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 40px;
    background: rgba(27, 117, 188, 0.1);
    border-radius: 500px;
    margin-bottom: 10px;
    p {
      display: flex;
      align-items: flex-end;
      img {
        margin-right: 5px;
      }
    }
  }
}
.symbol-style {
  max-height: 400px;
  overflow-y: auto;
  margin-top: 20px;
  .symbol-type {
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
    .symbol-img {
      margin-left: 30px;
      margin-right: 20px;
    }
    .symbol-name {
      font-size: 12px;
      font-family: Arial;
      font-weight: 700;
      color: #2c3034;
      text-align: left;
    }
    .symbol-desc {
      font-size: 12px;
      font-family: Arial;
      font-weight: 400;
      line-height: 20px;
      color: #2c3034;
      text-align: left;
      margin-top: 10px;
      margin-right: 30px;
    }
  }
}

.new-page {
  padding: 20px;
  .hospital-type {
    font-size: 14px;
    font-family: Arial;
    font-weight: 700;

    color: #2c3034;
    text-align: left;
    margin: 30px 0;
  }
  .expand-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .proof-text {
    font-size: 14px;
    font-family: Arial;
    font-weight: 700;
    color: #2c3034;
    text-align: left;
    margin: 20px 0;
  }
  img {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .document-link {
    padding: 20px;
    .link {
      font-size: 14px;
      font-family: Arial;
      font-weight: 400;
      line-height: 1.4;
      color: #1b75bc;
      text-align: left;
      margin-bottom: 10px;
      a {
        text-decoration: underline;
      }
    }
  }
  .expand-style {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    .expend-btn {
      color: #838a95;
      width: 50px;
    }
    .arrow-down-flag {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-top: 1px solid #838a95;
      border-left: 1px solid #838a95;
      transform: rotate(225deg);
      margin-right: 5px;
      margin-bottom: 2px;
    }
    .arrow-up-flag {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-top: 1px solid #838a95;
      border-left: 1px solid #838a95;
      transform: rotate(45deg);
      margin-right: 5px;
      margin-bottom: -2px;
    }
  }
  .interval {
    height: 16px;
    background: #f5f7f8;
  }
}
.detail-tilte {
  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  color: #2c3034;
  margin-bottom: 20px;
  text-align: left;
  line-height: 1.2;
}
.diglog-title {
  display: flex;
  padding: 0 10px 10px;
  .diglog-title-text {
    width: 90%;
    font-size: 16px;
    font-family: Arial;
    font-weight: bold;
    color: #2c3034;
  }
  .diglog-title-close {
    font-size: 30px;
  }
}
.diglog-content {
  .diglog-content-title {
    font-size: 14px;
    font-family: Arial;
    font-weight: bold;
    color: #2c3034;
    text-align: left;
    margin: 20px 0;
  }
}
.loading {
  margin-top: 55%;
}
.download {
  z-index: 1;
  position: absolute;
  top: 450px;
  right: 20px;
  border: 1px solid rgb(218, 217, 217);
  border-radius: 100%;
  padding: 5px;
}
</style>
